import styles from './Banner.module.scss';
import BannerIMG from '../../../assets/images/banner.gif';

export const Banner = () => {
    return (
        <section className={styles.banner}>
            <h1 className={styles['banner__title--mobile']}>
        Empréstimo rápido, <br /> seguro e muito fácil!
            </h1>
            <div className={styles.banner__info}>
                <h1 className={styles.banner__title}>
          Empréstimo rápido, <br /> seguro e muito fácil!
                </h1>
                <p>
          Trabalhamos para entregar a melhor experiência, <br />
          produtos e serviços certos proporcionando tranquilidade e conforto.
                    <br /> Temos muito orgulho de ter transformado tantas vidas em nossa
          história.
                </p>
                <div className={styles.banner__buttons}>
                    <a
                        href="http://api.whatsapp.com/send?phone=5513997121143"
                        target="_BLANK"
                        rel="noopener noreferrer"
                    >
                        <div>Solicitar empréstimo</div>
                    </a>
                    <a href="/servicos">
                        <div>nossos serviços</div>
                    </a>
                </div>
            </div>
            <img src={BannerIMG} alt="" />
        </section>
    );
};
