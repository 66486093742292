import { About } from '../components/Home/About/About';
import { Assessments } from '../components/Home/Assessments/Assessments';
import { Banks } from '../components/Home/Banks/Banks';
import { Banner } from '../components/Home/Banner/Banner';
import { Contact } from '../components/Home/Contact/Contact';
import { Faq } from '../components/Home/Faq/Faq';
import { Services } from '../components/Home/Services/Services';

export const Home = () => {
    return (
        <>
            <Banner />
            <Services />
            <About />
            <Assessments />
            <Contact />
            <Banks />
            <Faq />
        </>
    );
};
